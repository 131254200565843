"use strict";

var abp = abp || {};

(function () {
    if (!FreezeUI || !UnFreezeUI) {
        return;
    }

    abp.ui.setBusy = function (elm, text, delay, cssClass) {
        FreezeUI({
            element: elm,
            text: text ? text : " ",
            delay: delay,
            cssClass: cssClass
        });
    };

    abp.ui.clearBusy = function (elm, delay, cssClass) {
        UnFreezeUI({
            element: elm,
            delay: delay,
            cssClass: cssClass
        });
    };
})();
